const axios = require("axios");
// import router from "@/router";


import { getBaseUrls } from "@/utils/getBaseUrls";


// import * as sweetalert from "sweetalert";
import useLogout from '@/composables/useLogout';
import moment from 'moment';

import store from '@/store'


const {
    baseURL,
    inboxBaseURL,
    integrationBaseURL,
    teamsBaseURL,
    aiBaseURL,
    logEventsBaseURL,
    reportsBaseURL,
    activityBaseURL
} = getBaseUrls();

const createAxiosInstance = (url) =>
    axios.create({
        baseURL: url, // Dynamically passed
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "",
        },
    });

const logEventsInstance = createAxiosInstance(logEventsBaseURL);
const integrationsInstance = createAxiosInstance(integrationBaseURL);
const instance = createAxiosInstance(baseURL);
const aiInstance = createAxiosInstance(aiBaseURL);
const inboxInstance = createAxiosInstance(inboxBaseURL);
const inboxWidgetInstance = createAxiosInstance(inboxBaseURL);
const teamsInstance = createAxiosInstance(teamsBaseURL);
const authInstance = createAxiosInstance(inboxBaseURL);
const reportsInstance = createAxiosInstance(reportsBaseURL)
const activityInstance = createAxiosInstance(activityBaseURL)

instance.interceptors.request.use(async(config) => {
    const token = localStorage.getItem('inbox-chat-token');
    let tokenTime = localStorage.getItem('token-time');
    if (tokenTime) {
        const now = moment.utc();
        let storedTime = moment.utc(+tokenTime).valueOf();

        // Calculate the difference in minutes (or hours, if needed)
        let diffInHours = now.diff(storedTime, 'hours');
        // Check if more than 23 minutes have passed
        if (diffInHours >= 23) {
            // console.log("23 hours have passed since the given time.");

            let payload = {
                email: localStorage.getItem('user_email'),
                user_id: localStorage.getItem('user_id'),
                support_lib_id:localStorage.getItem('support_Lib_id')
            };

            // Use the separate authInstance to avoid circular dependency
            try {
                const res = await authInstance.post('/login', payload);

                // Store the new token and update token time
                if(res.data.token) {
                    localStorage.setItem('inbox-chat-token', res.data.token); // Assuming response has token
                    let nowTimeStamp = moment.utc().valueOf().toString();
                    localStorage.setItem('token-time', nowTimeStamp);
                }
            } catch (err) {
               store.dispatch('printLog', {text:'Error while refetching token', log:err});
            }
        } else {
            // console.log("Less than 23 hours have passed.");
        }
    }
    
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    config.validateStatus = (status) => status < 400;
    return config;
});


teamsInstance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('inbox-chat-token');
    let tokenTime = localStorage.getItem('token-time');
    if (tokenTime) {
        const now = moment.utc();
        let storedTime = moment.utc(+tokenTime).valueOf();
        let diffInHours = now.diff(storedTime, 'hours');
        if (diffInHours >= 23) {
            let payload = {
                email: localStorage.getItem('user_email'),
                user_id: localStorage.getItem('user_id'),
                support_lib_id:localStorage.getItem('support_Lib_id')
            };
            try {
                const res = await authInstance.post('/login', payload);
                if (res.data.token) {
                    localStorage.setItem('inbox-chat-token', res.data.token);
                    let nowTimeStamp = moment.utc().valueOf().toString();
                    localStorage.setItem('token-time', nowTimeStamp);
                }
            } catch (err) {
                store.dispatch('printLog', {text:'Error while refetching token', log:err});
            }
        } else {
            // console.log("Less than 23 hours have passed.");
        }
    }
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    config.validateStatus = (status) => status < 400;
    return config;
});

inboxInstance.interceptors.request.use(async(config) => {
    const token = localStorage.getItem('inbox-chat-token');
    let tokenTime = localStorage.getItem('token-time');
    if (tokenTime) {
        const now = moment.utc();
        let storedTime = moment.utc(+tokenTime).valueOf();

        // Calculate the difference in hours (or hours, if needed)
        let diffInHours = now.diff(storedTime, 'hours');
        // Check if more than 23 hours have passed
        if (diffInHours >= 23) {
            // console.log("23 hours have passed since the given time.");

            let payload = {
                email: localStorage.getItem('user_email'),
                user_id: localStorage.getItem('user_id'),
                support_lib_id:localStorage.getItem('support_Lib_id')
            };

            // Use the separate authInstance to avoid circular dependency
            try {
                const res = await authInstance.post('/login', payload);

                // Store the new token and update token time
                if(res.data.token) {
                    localStorage.setItem('inbox-chat-token', res.data.token); // Assuming response has token
                    let nowTimeStamp = moment.utc().valueOf().toString();
                    localStorage.setItem('token-time', nowTimeStamp);
                }
            } catch (err) {
                store.dispatch('printLog', {text:'Error while refetching token', log:err});
            }
        } else {
            // console.log("Less than 23 hours have passed.");
        }
    }

    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    config.validateStatus = (status) => status < 400;
    return config;
});

reportsInstance.interceptors.request.use(async(config) => {
    const token = localStorage.getItem('inbox-chat-token');
    let tokenTime = localStorage.getItem('token-time');
    if (tokenTime) {
        const now = moment.utc();
        let storedTime = moment.utc(+tokenTime).valueOf();

        // Calculate the difference in hours (or hours, if needed)
        let diffInHours = now.diff(storedTime, 'hours');
        // Check if more than 23 hours have passed
        if (diffInHours >= 23) {
            // console.log("23 hours have passed since the given time.");

            let payload = {
                email: localStorage.getItem('user_email'),
                user_id: localStorage.getItem('user_id'),
                support_lib_id:localStorage.getItem('support_Lib_id')
            };

            // Use the separate authInstance to avoid circular dependency
            try {
                const res = await authInstance.post('/login', payload);

                // Store the new token and update token time
                if(res.data.token) {
                    localStorage.setItem('inbox-chat-token', res.data.token); // Assuming response has token
                    let nowTimeStamp = moment.utc().valueOf().toString();
                    localStorage.setItem('token-time', nowTimeStamp);
                }
            } catch (err) {
                store.dispatch('printLog', {text:'Error while refetching token', log:err});
            }
        } else {
            // console.log("Less than 23 hours have passed.");
        }
    }

    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    config.validateStatus = (status) => status < 400;
    return config;
});

activityInstance.interceptors.request.use(async(config) => {
    const token = process.env.VUE_APP_ACTIVITY_API_KEY
    if (token && config.headers) config.headers.Authorization = token;
    config.validateStatus = (status) => status < 400;
    return config;
});

inboxInstance.interceptors.response.use(
    (successRes) => {
       
        // console.log('successRes', successRes)
        return successRes;
    },
    (error) => {
        // console.log('error', error)
        // sweetalert({
        //     title: "Error!",
        //     text: error?.response?.data?.message
        //         ? error?.response?.data?.message
        //         : "An error occurred",
        //     icon: "error",
        //     buttons: {
        //         confirm: {
        //             text: "Close",
        //             value: true,
        //             visible: true,
        //             className: "btn bg-gradient-danger mx-auto",
        //             closeModal: true,
        //         },
        //     },
        // });
        store.dispatch('printLog', {text:"caught error", log:error?.response?.status});
        const { logout } = useLogout();
        if(error?.response?.status == 401) {
            logout()
        }
        return Promise.reject(error);
    }
);

const Api = {
    getSopCategories: async (support_lib_id) => {
        return await instance.get(`sop_categories?support_lib_id=${support_lib_id}`);
    },
    getSopArticlesbackend:async (params) => {
        let url = `sop_articles?support_lib_id=${params.support_lib_id}&rut=admin&published=all`
        if(params.category_id) {
            url += `&category_id=${params.category_id}`
        }
        if(params.article_id) {
            url += `&article_id=${params.article_id}`
        }
        if(params.per_page) {
            url += `&per_page=${params.per_page}`
        }
        if(params.first_document_id) {
            url += `&per_page=${params.first_document_id}`
        }
        if(params.last_document_id) {
            url += `&per_page=${params.last_document_id}`
        }
        return await instance.get(url);
    },
    getCoursesLessonsbackend:async (params) => {
        let url = `courses_lessons?support_lib_id=${params.support_lib_id}&rut=admin&published=all`
        if(params.course_id) {
            url += `&course_id=${params.course_id}`
        }
        if(params.lesson_id) {
            url += `&lesson_id=${params.lesson_id}`
        }
        return await instance.get(url);
    },
    getCoursesLessonsFrontend:async (params) => {
        let url = `courses_lessons?support_lib_id=${params.support_lib_id}`
        if(params.course_id) {
            url += `&course_id=${params.course_id}`
        }
        if(params.lesson_id) {
            url += `&lesson_id=${params.lesson_id}`
        }
        return await instance.get(url);
    },
    getCourses: async (params) => {
        let url = `courses?support_lib_id=${params.support_lib_id}&rut=admin&published=all`
        if(params.library_type) {
            url += `&library_type=${params.library_type}`
        }
        return await instance.get(url);
    },
    getSubscription: async () => {
        return await instance.get('subscriptions')
    },
    getNotifications:async (params) => {
        return await inboxInstance.get(`/agent_notifications?support_lib_id=${params.support_lib_id}`)
    },
    updateNotifications:async (payload) => {
        return await inboxInstance.put(`/agent_notifications`, payload)
    },
    login:async(payload) => {
        return await inboxInstance.post(`/login`, payload)
    },

    regenerateToken:async() => {
        let payload  = {
            email:localStorage.getItem('user_email'),
            user_id:localStorage.getItem('user_id')
        }
        try {
            let res =  await axios.post(`${inboxBaseURL}/login`, payload)
            if(res?.data?.token) {
                localStorage.setItem('inbox-chat-token', res.data.token)
            }
        } 
        catch(error)  {
            store.dispatch('printLog', {text:'payload error', log:error})
        }   
    },

    getNotificationSubscription:async(params) => {
        let url = `/notification_subscriptions?support_lib_id=${params.support_lib_id}`;
        if(params.include_parent_lib_id) {
            url += `&include_parent_lib_id=${params.include_parent_lib_id}`
        }
        return await inboxInstance.get(url)
    },

    submitNewNotificationSubscription:async(payload) => {
        return await inboxInstance.post('/notification_subscriptions', payload)
    },

    updateNotificationSubscription:async(params, payload) => {
        let url = `/notification_subscriptions?support_lib_id=${params.support_lib_id}`;
        return await inboxInstance.put(url, payload)
    },

    deleteNotificationSubscription:async(payload) => {
        return await inboxInstance.delete(`/notification_subscriptions`, {data:payload})
    },


    updateAgentInformation:async (payload) => {
        return await inboxInstance.put(`/user`, payload)
    },
    getAgentList:async (params) => {
        let url = `/agents?support_lib_id=${params.support_lib_id}`
        if(params.parent_lib_id) {
            url += `&parent_lib_id=${params.parent_lib_id}&include_parent_lib_id=1`
        }
        if(params?.rt) {
            url += `&rt=${params.rt}`
        }
        if(params?.mode) {
            url += `&mode=${params.mode}`
        }
        if(params?.hide_self) {
            url += `&hide_self=${params.hide_self}`
        }
        return await inboxInstance.get(url);
    },
    getAgentListForWidget:async (params) => {
        let url = `/agents?support_lib_id=${params.support_lib_id}`
        if(params.parent_lib_id) {
            url += `&parent_lib_id=${params.parent_lib_id}&include_parent_lib_id=1`
        }
        if(params.rt) {
            url += `&rt=${params.rt}`
        }
        if(params.mode) {
            url += `&mode=${params.mode}`
        }
        return await inboxWidgetInstance.get(url);
    },
    getLoggedInUser:async () => {
        return await inboxInstance.get(`/user`);
    },
    deleteAgentProfileImage:async(payload) => {
        return await inboxInstance.put(`user`, payload)
    },
    getAgencyDetails:async(support_lib_id) => {
        return await inboxInstance.get(`agency?support_lib_id=${support_lib_id}`)
    },
    updateAgencyAction:async(payload) => {
        const token = localStorage.getItem('inbox-chat-token');
        const headers  = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,
        }
        return await inboxInstance.patch(`agency` , payload, {headers:headers})
    },
    submitAgencyDetails:async(payload) => {
        const token = localStorage.getItem('inbox-chat-token');
        const headers  = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,
        }
        return await inboxInstance.post(`agency` , payload, {headers:headers})
    },
    updateAgencyDetails:async (payload) => {
        const token = localStorage.getItem('inbox-chat-token');
        const headers  = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,
        }
        return await inboxInstance.put(`agency` , payload, {headers:headers})
    },
    
    getTicketStatuses:async(support_lib_id) => {
        return await inboxInstance.get(`ticket_statuses?support_lib_id=${support_lib_id}`)
    },
    updateTicketStatus:async(payload) => {
        return await inboxInstance.patch(`tickets`, payload)
    },
    listTicketCategories:async(support_lib_id) => {
        return await inboxInstance.get(`ticket_categories?support_lib_id=${support_lib_id}`)
    },
    submitNewTicketCategory:async(payload) => {
        return await inboxInstance.post(`ticket_categories`, payload)
    },
    updateTicketCategory:async(payload, ticket_category_ref) => {
        return await inboxInstance.put(`ticket_categories?ticket_category_ref=${ticket_category_ref}`, payload)
    },
    listTicketTiers:async(support_lib_id) => {
        return await inboxInstance.get(`ticket_tiers?support_lib_id=${support_lib_id}`)
    },
    submitNewTicketTier:async(payload) => {
        return await inboxInstance.post(`ticket_tiers`, payload)
    },
    updateTicketTier:async(payload, ticket_tier_ref) => {
        return await inboxInstance.put(`ticket_tiers?ticket_tier_ref=${ticket_tier_ref}`, payload)
    },
    removeGoogleIntegration:async(payload) => {
        return await integrationsInstance.delete(`google/auth`, {data:payload})
    },
    removeMailgunIntegration:async(payload) => {
        return await integrationsInstance.delete(`mailgun/auth`, {data:payload})
    },
    updateTicketCategoryAndTierStatus:async(payload) => {
        return await inboxInstance.patch(`tickets`, payload)
    },
    searchCoPilot:async(payload) => {
        return await aiInstance.post(`search`, payload)
    },
    listChildLibraries:async(params) => {
        return await inboxInstance.get(`agency?support_lib_id=${params.support_lib_id}&list_children=1`)
    },
    getWorkflows:async (params) => {
        let url = `workflows?support_lib_id=${params.support_lib_id}`;
        if(params?.workflow_ref) {
            url += `&workflow_ref=${params?.workflow_ref}`
        }
        if(params?.is_enabled !== undefined) {
            url += `&is_enabled=${params?.is_enabled}`
        }
        if(params?.q) {
            url += `&q=${params.q}`
        }
        if(params?.per_page) {
            url += `&per_page=${params.per_page}`
        }
        if(params?.page) {
            url += `&page=${params.page}`
        }
        if(params?.order_by) {
            url += `&order_by=${params.order_by}`
        }
        if(params?.order) {
            url += `&order=${params.order}`
        }
        if(params?.workflow_folder_ref) {
            url += `&workflow_folder_ref=${params?.workflow_folder_ref}`
        }
        return await inboxInstance.get(url)
    },
    createWorkflow:async (payload) => {
        return await inboxInstance.post(`workflows`, payload)
    },
    updateWorkflow:async(payload, params) => {
        return await inboxInstance.put(`workflows?workflow_ref=${params.workflow_ref}`, payload)
    },
    deleteWorkflow:async(payload) => {
        return await inboxInstance.delete(`workflows`, {data:payload})
    },
    getWorkflowFolders:async (params) => {
        let url = `workflow_folders?support_lib_id=${params.support_lib_id}`;
        if(params?.workflow_ref) {
            url += `&workflow_folder_ref=${params?.workflow_folder_ref}`
        }
        if(params?.workflow_folder_ref) {
            url += `&workflow_folder_ref=${params?.workflow_folder_ref}`
        }
        return await inboxInstance.get(url)
    },
    createWorkflowFolder:async (payload) => {
        return await inboxInstance.post(`workflow_folders`, payload)
    },
    updateWorkflowFolder:async(payload, params) => {
        return await inboxInstance.put(`workflow_folders?workflow_folder_ref=${params.workflow_folder_ref}`, payload)
    },
    deleteWorkflowFolder:async(payload) => {
        return await inboxInstance.delete(`workflow_folders`, {data:payload})
    },
    getModalWidgetDetails:async (params) => {
        return await inboxInstance.get(`modal_widgets?support_lib_id=${params.support_lib_id}`)
    },
    getModalWidgetDetailsForWidget:async (params = undefined) => {
        let url = `modal_widgets`
        const queryParams = []
        if(params?.support_lib_id !== undefined) {
            queryParams.push(`support_lib_id=${params.support_lib_id}`)
        }
        if(params?.sm !== undefined) {
            queryParams.push(`sm=${params.sm}`)
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await inboxWidgetInstance.get(url)
    },
    fetchTags:async (params) => {
        let url = `conv_tags?support_lib_id=${params.support_lib_id}`
        if(params?.conv_ref) {
            url += `&conv_ref=${params.conv_ref}`
        }
        if(params?.show_all) {
             url += `&show_all=${params.show_all}`
        }
        return await inboxInstance.get(url)
    },
    addTags:async(payload) => {
        return await inboxInstance.post(`conv_tags`, payload)
    },

    getMentionChats:async(url) => {
        return await inboxInstance.get(url)
    },

    updateconversation:async(payload) => {
        return await inboxInstance.patch(`conversations`, payload)
    },

    getPermissions:async(params) => {
        return await teamsInstance.get(`permissions/${params.ref_uid}/${params.role}`)
    },

    updatePermissions:async(payload, ref_uid) => {
        return await teamsInstance.put(`updatepermissions/${ref_uid}`, payload) 
    },

    getOrganizationRoles:async(support_lib_id) => {
        return await teamsInstance.get(`roles/${support_lib_id}`)
    },

    getLogEvents:async(params) => {
        let url = `EventHistory`;
        if(params?.workflow_ref) {
            url += `/${params?.workflow_ref}/1`;
        }
        if(params?.threadId) {
            url += `/${params?.threadId}/2`;
        }
        return await logEventsInstance.get(url)
    },

    getScreenShareData:async(params, payload)=> {
         let url = `cobrowsewatchurl/${params.visitor_ref}`
        return await teamsInstance.post(url, payload)
    },

    ticketCreatedMethods:async(params) => {
        let url = `ticket_methods?support_lib_id=${params.support_lib_id}`
        return await inboxInstance.get(url)
    },

    getIntegrationsEmail:async(params, payload) => {
        let url =`/${params?.emailType}?support_lib_id=${params.support_lib_id}`
        return await integrationsInstance.get(url)
    },

    changeIntegrationsEmail:async(params, payload) => {
        let url =`/${params?.emailType}?supportLibId=${params.support_lib_id}`
        return await integrationsInstance.put(url, payload)
    },

    /* form builder */
    getFormbuilderForms: async (params) => {
        let url = `getformlist`;
        if (params?.per_page) {
            url += `?per_page=${params.per_page}`;
        }
        if (params?.page) {
            url += `&page=${params.page}`;
        }
        if (params?.search) {
            url += `&search=${params.search}`;
        }
        return await teamsInstance.get(url);
    },

    updateForm: async (params, payload) => {
        return await teamsInstance.patch(`renameform/${params.contact_form_ref}`, payload)
    },

    deleteForm: async (params) => {
        return await teamsInstance.delete(`deleteform/${params.contact_form_ref}`)
    },

    updateFormStatus: async (params, payload) => {
        return await teamsInstance.patch(`publishform/${params.contact_form_ref}`, payload)
    },
    
    getForm:async (params) => {
        let url = `/getform`
        if(params?.contact_form_ref) {
            url += `/${params?.contact_form_ref}`
        }
        return await teamsInstance.get(url)
    },

    markMentionAsSeen:async(payload) => {
        return await inboxInstance.patch(`messages`, payload)
    },

    getConversationFormFields:async(params) => {
        let url = `/widget_form_submissions?conversation_ref=${params.conversation_ref}`
        return await inboxInstance.get(url)
    },
    /* ends here */


    getSmartQueuesList:async(params = undefined) => {
        let url = `smart_queues`;
       
        const queryParams = []
        if(params?.sm !== undefined) {
            queryParams.push(`sm=${params.sm}`)
        }
        if(params?.per_page !== undefined) {
            queryParams.push(`per_page=${params.per_page}`)
        }
        if(params?.page !== undefined) {
            queryParams.push(`page=${params.page}`)
        }
        if(params?.smart_queue_ref !== undefined) {
            queryParams.push(`smart_queue_ref=${params.smart_queue_ref}`)
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await inboxInstance.get(url)
    },

    createSmartQueue:async(payload) => {
        let url = `smart_queues`;
        return await inboxInstance.post(url, payload)
    },

    updateSmartQueue:async(payload,params) => {
        let url = `smart_queues?smart_queue_ref=${params.smart_queue_ref}`;
        return await inboxInstance.put(url, payload)
    },

    deleteSmartQueue:async(payload) => {
        let url = `smart_queues`;
        return await inboxInstance.delete(url, {data:payload})
    },

    getCsatOverview:async(params = undefined) => {
        let url = `getcsatoverview`;
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }
        
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    },

    getConversationsCount:async(params = undefined) => {
        let url = `getconversationscount`;
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    },

    getSupportOverview:async(params = undefined) => {
        let url = `getsupportoverview`;
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    },

    getTicketReports:async(params = undefined) => {
        let url = `getticketreports`
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }

        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    },

    getChatReports:async(params = undefined) => {
        let url = `getchatreports`
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    },

    getDetailedConversations:async(params = undefined) => {
        let url = `getdetailedconversations`
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    },

    getDetailedChats:async(params = undefined) => {
        let url = `getdetailedchats`
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    },

    getDetailedTicket:async(params = undefined) => {
        let url = `getdetailedtickets`
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    },

    getDraftMessages:async(params) => {
        let url = `draft_messages`
        const queryParams = []
        if(params?.draft_message_ref) {
            queryParams.push(`draft_message_ref=${params.draft_message_ref}`)
        }
        if(params?.conversation_ref) {
            queryParams.push(`conversation_ref=${params.conversation_ref}`)
        }
        if(params?.review_for) {
            queryParams.push(`review_for=${params.review_for}`)
        }
        if(params?.review_status) {
            queryParams.push(`review_status=${params.review_status}`)
        }

        if(params.per_page) {
            queryParams.push(`per_page=${params.per_page}`)
        }
        if(params.page) {
            queryParams.push(`page=${params.page}`)
        }
        if(params?.timezone) {
            queryParams.push(`timezone=${params.timezone}`)
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return inboxInstance.get(url)
    },

    getDetailedCustomerSatisfaction:async(params = undefined) => {
        let url = `getteammateratings`
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    },

    getTeamsList:async(params) => {
        let url = `teams`
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }
        
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await inboxInstance.get(url)
    },

    getTeammates:async(params) => {
        let url = `agents`
        const queryParams = [];
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }
        
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await inboxInstance.get(url)
    },

    updateTeammateInfo:async(payload) => {
        let url = 'agents'
        return await inboxInstance.patch(url, payload)
    },

    deleteTeam:async(payload) => {
        let url = 'teams'
        return await inboxInstance.delete(url, {data:payload})
    },

    updateTeam:async(params, payload) => {
        let url = 'teams'
        const queryParams = [];
        if(params?.team_ref) {
            queryParams.push(`team_ref=${params.team_ref}`)
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await inboxInstance.put(url, payload)
    },

    createTeam:async(payload) => {
        let url = 'teams'
        return await inboxInstance.post(url, payload)
    },

    createSearchFilter:async(payload) => {
        let url = 'saved_filters'
        return await inboxInstance.post(url, payload)
    },

    updateSearchFilter:async(params, payload) => {
        let url = `saved_filters?filter_ref=${params.filter_ref}`
        return await inboxInstance.put(url, payload)
    },

    getSearchFilterList:async(params = undefined) => {
        let url = 'saved_filters'
        const queryParams = [];
        if(params?.filter_ref) {
            queryParams.push(`filter_ref=${params.filter_ref}`)
        }
        if(params?.per_page) {
            queryParams.push(`per_page=${params.per_page}`)
        }
        if(params?.page) {
            queryParams.push(`page=${params.page}`)
        }
        if(params?.filter_name) {
            queryParams.push(`filter_name=${params.filter_name}`)
        }
        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await inboxInstance.get(url)
    },

    deleteSmartSearchList:async(payload) => {
        let url = 'saved_filters'
        return await inboxInstance.delete(url, {data:payload})
    },

    addArticleActivity:async(payload) => {
        let url = 'articles'
        return await activityInstance.post(url, payload)
    },

    addSearchActivity:async(payload) => {
        let url = 'searches'
        return await activityInstance.post(url, payload)
    },

    updateSearchActivity:async(payload) => {
        let url = 'searches'
        return await activityInstance.patch(url, payload)
    },


    addNewsFlashActivity:async(payload) => {
        let url = 'newsflash'
        return await activityInstance.post(url, payload)
    },

    addWidgetActivity:async(payload) => {
        let url = 'widget'
        return await activityInstance.post(url, payload)
    },

    addChatsActivity:async(payload) => {
        let url = 'chats'
        return await activityInstance.post(url, payload)
    },

    sendApplicationWebSocket(payload) {
        let token = localStorage.getItem('inbox-chat-token')
        let data = {...payload, sent_via:"application" }
        if(token) {
            data = {...data, sender_ref:token}
        }
        store.dispatch('printLog', {text:`action ${data.action}`, log:data})
        if(store.state.socket) {
            store.state.socket.send(JSON.stringify(data))
        }
    },

    sendWidgetWebSocket(payload) {
        let data = {...payload, sent_via:"widget" }
        store.dispatch('printLog', {text:`action ${data.action}`, log:data})
        if(store.state.socket) {
            store.state.socket.send(JSON.stringify(data))
        }
    },

    //reports knowledge analytics
    async getReportsAnalytics(params) {
        let url = `analytics`
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }

        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    },

    async getDetailedReportsAnalytics(params) {
        let url = `detailedanalytics`
        const queryParams = []
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const element = params[key];
                if(element) {
                    queryParams.push(`${key}=${element}`)
                }
            }
        }

        if(queryParams?.length) {
            url += `?${queryParams.join('&')}`
        }
        return await reportsInstance.get(url)
    }
}

export default Api;
